<template>
  <!--
    The view for the Acknowledge Messages
  -->
  <Portlet
    :title="$t('menu.acknowledgeMessages')"
    icon="band-aid"
    class="acknowledgeMessages"
  >
    <template slot="buttons">
      <button 
        class="btn btn-sm btn-primary float-right mt-2 mb-2" 
        @click="showSidebarAdd"
      >
        <font-awesome-icon
          class="mr-1"
          icon="plus"
        />
        <span>
          {{ $t('add') }}
        </span>
      </button>
      <div class="clearfix" />
    </template>
    <AcknowledgeMessagesList
      ref="acknowledgeMessagesList" 
      @reloadAuditLogs="reloadAuditLogs"
    /> 
    <DatabaseAuditLogList
      ref="databaseAuditLogList"
      :is-ack-messages="true"
      @reload="reloadAcknowledgeMessagesList"
    />
  </Portlet>
</template>

<script>
export default {
  name: "AcknowledgeMessages",
  components: {
    AcknowledgeMessagesList: () => import('@/components/AcknowledgeMessages/AcknowledgeMessagesList.vue'),
    DatabaseAuditLogList: () => import('@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue')
  },
  metaInfo () {
    return {
      title: this.$t('menu.acknowledgeMessages')
    }
  },
  methods: {
    showSidebarAdd () {
      this.$refs.acknowledgeMessagesList.showSidebarAdd();
    },
    reloadAuditLogs () {
      this.$refs.databaseAuditLogList.reloadAuditLogs();
    },
    reloadAcknowledgeMessagesList () {
      this.$refs.acknowledgeMessagesList.reloadAcknowledgeMessages();
    }
  }
}
</script>
